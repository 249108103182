/* eslint-disable no-prototype-builtins */
import * as Storage from './storage';
import { regexSystem } from './constant';
import _ from 'lodash'
import moment from 'moment'

const AUTH_TYPE = 'Bearer';

/*****************************************************
                    VALIDAITON
******************************************************/

export const isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
}

export const isEmpty = (obj) => {
    for (var key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] !== null)
            return false;
    }
    return true;
}

export const isEmail = (email) => {
    const regexEmail = _.get(regexSystem, "email.pattern", {});
    if (regexEmail.match) {
        const match = new RegExp(regexEmail.match);
        if (match.test(email) === false) {
            return {
                result: false,
                message: regexEmail.message
            };
        }
    }
    return true;
}

export const LIMIT = 20
export const TIME_OUT = 3000
export const INIT_PAGE = 1
export const PAGE_DISPLAY = 5
export const SORT_ASC = 'ASC'
export const SORT_DESC = 'DESC'

export const isConfirmPassWord = (password, passwordConfirm) => {
    if (password.localeCompare(passwordConfirm) !== 0) {
        return false
    }
    return true;
}

export const isPassword = (text) => {
    const regexPassWord = _.get(regexSystem, "password.pattern", {});
    if (regexPassWord.match) {
        const match = new RegExp(regexPassWord.match);
        if (match.test(text) === false) {
            return false
        }
    }
    return true;
}

export const isPhone = (zipCode) => {
    const regexPhone = _.get(regexSystem, "phone.pattern", {});
    if (regexPhone.match) {
        const match = new RegExp(regexPhone.match);
        if (match.test(zipCode) === false) {
            return false
        }
    }
    return true;
}

export const isZipCode = (zipCode) => {
    const regexZipCode = _.get(regexSystem, "zipCode.pattern", {});
    if (regexZipCode.match) {
        const match = new RegExp(regexZipCode.match);
        if (match.test(zipCode) === false) {
            return false
        }
    }
    return true;
}

export const isAddress = (address) => {
    const regexAddress = _.get(regexSystem, "address.pattern", {});
    if (regexAddress.match) {
        const match = new RegExp(regexAddress.match);
        if (match.test(address) === false) {
            return false
        }
    }
    return true;
}

export const isWebsite = (website) => {
    const regexWebsite = _.get(regexSystem, "website.pattern", {});
    if (regexWebsite.match) {
        const match = new RegExp(regexWebsite.match);
        if (match.test(website) === false) {
            return false
        }
    }
    return true;
}
/**
 * Check value positive integers
 * @param {*} value 
 */
export const isPositiveIntegers = (value) => {
    var valueInt = parseInt(value);
    var valueStr = valueInt.toString();
    const match = new RegExp("^\\d*$");
    if (match.test(value) && valueInt >= 1 && (value === valueStr)) {
        return true;
    }
    return false;
}

/*****************************************************
                    FORMAT DATE TYPE
******************************************************/

export const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD")
}

// moment().format('DDDD Do YYYY, h:mm:ss a'); // August 28th 2021, 1:05:51 am
export const formatDateString = (date) => {
    return moment(date).format("MM/DD/YYYY"); // DD MMM YY, h:mm a
}

/*****************************************************
                    CONVERT TYPE AND STRING
******************************************************/
export const convertType = (type, dataFix) => {
    let result = "";
    if (dataFix && Array.isArray(dataFix)) {
        dataFix.forEach(element => {
            if (element.key === type) {
                result = element.value;
                return;
            }
        });
    }
    return result;
}

export const convertToSlug = (Text) => {
    return Text.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
}

export const replaceAll = (str, find, replace) => {
    return str.replace(new RegExp(find, 'g'), replace);
}

/**
 * logout : remove storage token and redirect
 * @param {*} redirect_url 
 */
export const logout = (redirect_url = null) => {
    // remove userInfo and jwtToken from storage to log user out
    localStorage.removeItem(Storage.JWT_TOKEN);
    localStorage.removeItem(Storage.USER_INFO);
    if (redirect_url) {
        window.location.replace(`/auth/login?redirect=${redirect_url}`);
    } else {
        window.location.replace("/auth/login");
    }
}

export const getFileExtension = (fileName) => {
    let ext = undefined;
    let result = (/[.]/.exec(fileName)) ? /[^.]+$/.exec(fileName) : undefined;
    if (result !== undefined && Array.isArray(result)) {
        ext = result[0];
    }
    return ext;
}

/*****************************************************
                    API calls
******************************************************/

export const Authorization = () => {
    let jwtToken = JSON.parse(localStorage.getItem(Storage.JWT_TOKEN));
    if (jwtToken) {
        return AUTH_TYPE + ' ' + jwtToken;
    }
    return "";
}

export function commonHeaders() {
    // return authorization header with basic auth credentials
    let jwtToken = JSON.parse(localStorage.getItem(Storage.JWT_TOKEN));
    if (jwtToken) {
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AUTH_TYPE + ' ' + jwtToken
        };
    } else {
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
    }
}

export const ObjectToQueryString = function (object) {
    if (!object)
        return '';
    let out = [];
    for (let key in object) {
        out.push(key + '=' + encodeURIComponent(object[key]));
    }

    return out.join('&');
}

// fetch doesn't reject on on-200s so this helper does that
// response.ok checks for HTTP status codes in the 200 range
export const handleNon200s = (response) => {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            data.error = true;
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                setTimeout(function () {
                    logout();
                }, 3000);
            }
            if (response.status === 403 || response.status === 404) {
                window.location.replace("/forbidden")
            }
        }
        return data;
    });
}

export const handleAuthNon200s = (response) => {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            data.error = true;
        }
        return data;
    });
}
