// Role use
export const ROLE = {
    ADMIN: "ADMIN",
    USER: "USER",
};

// Container name
export const CONTAINER_NAME = {
}

// Regex
export const regexSystem = {
    email: {
        pattern: {
            match: /^\w+([.-]?\w+)*@\w+([-]?\w+)*(\.\w)+([.-]?\w+)+$/,
            register: /^\w+([.-]?\w+)*@\w+([-]?\w+)*(\.\w)+([.-]?\w+)+$/i,
            message: 'Email is invalid',
        }
    },
    ip: {
        pattern: {
            match: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
            register: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/i,
            message: 'Ip is invalid'
        }
    },
    phone: {
        pattern: {
            match: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s/0-9]*$/,
            register: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s/0-9]*$/i,
            message: 'Phone number is invalid'
        }
    },
    zipCode: {
        pattern: {
            match: /^[0-9]{5}(?:-[0-9]{4})?$/,
            register: /^[0-9]{5}(?:-[0-9]{4})?$/i,
            message: 'Zip code is invalid'
        }
    },
    address: {
        pattern: {
            match: /^(?=\S*\s)(?=[^a-zA-Z]*[a-zA-Z])(?=\D*\d)[a-zA-Z\d\s\',.#/-]*$/,
            register: /^(?=\S*\s)(?=[^a-zA-Z]*[a-zA-Z])(?=\D*\d)[a-zA-Z\d\s\',.#/-]*$/i,
            message: 'Address is invalid'
        }
    },
    website: {
        pattern: {
            match: /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
            register: /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/i,
            message: 'Website is invalid'
        }
    },
    password: {
        pattern: {
            match: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
            register: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/i,
            message: 'Password is invalid'
        }
    },
    number: {
        pattern: {
            match: /^\d+$/,
            register: /^\d+$/i,
            message: 'Number is invalid'
        }
    }
}


export const PASSWORD_LENGTH = {
    MIN: 8,
    MAX: 32
}