/* eslint-disable react/prop-types */
import React, { useState, useEffect, useLayoutEffect } from 'react';
import _ from 'lodash';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import InputGroup from 'react-bootstrap/InputGroup';
import { IconSquarePlus, IconCircleMinus } from '@tabler/icons';

const AddOptional = ({updateOptional, listOptional}) => {
    const [optional, setOptional] = useState(listOptional)
    
    const addOptional = () => {
        const dataUpdate = [...optional, {
            label: '',
            value: '',
            err_label: false,
            err_value: false
        }];
        setOptional(dataUpdate);
        updateOptional(dataUpdate)
        
    }
    const deleteOptional = (e, idx) => {
        if (_.size(optional) > 1) {
            const dataUpdate = optional.filter(function (item, key) {
                return key !== idx;
            })
            setOptional(dataUpdate);
            updateOptional([...dataUpdate]);
        }
    }
    const handleChange = (event) => {
        let data_name = event.target.name.split('-');
        if (data_name[1]) {
            const optId = data_name[1];
            const dataOptional = [...optional];
            if (['label', 'value'].includes(data_name[0])) {
                if (event.target.value.trim() === '') {
                    dataOptional[optId]['err_' + data_name[0]] = true;
                } else {
                    dataOptional[optId]['err_' + data_name[0]] = false;
                }
                dataOptional[optId][data_name[0]] = event.target.value;
                setOptional(dataOptional);
            }
        }
    }
    return (
        <>
            {Array.isArray(optional) && _.size(optional) > 0 && optional.map((val, idx) => {
                let labelId = `label-${idx}`;
                let valueId = `value-${idx}`;
                return <div key={idx} className='form-attributes'>
                    <InputGroup className="mt-3">
                        <InputGroup.Text>Label & Value<span className='required ms-1'>(*)</span></InputGroup.Text>
                        <Form.Control name={labelId} aria-describedby={labelId} className={`form-control round ${optional[idx].err_label && 'is-invalid'}`} value={optional[idx].label} onChange={(e) => handleChange(e)} />
                        <Form.Control name={valueId} aria-describedby={valueId} className={`form-control round ${optional[idx].err_value && 'is-invalid'}`} value={optional[idx].value} onChange={(e) => handleChange(e)} />
                        <InputGroup.Text className='cl-pointer col-auto' onClick={(e) => deleteOptional(e, idx)}><IconCircleMinus /></InputGroup.Text>
                    </InputGroup>
                </div>
            })}
            <Row className='mt-3'><IconSquarePlus className='link-primary cl-pointer' size={24} onClick={addOptional} /></Row>
        </>
    );
}

export default AddOptional;