import fetch from 'isomorphic-fetch';
import * as Utils from '../../utils';
import { API_ROOT } from '../../config';

const BASE_URL = API_ROOT;

export const getAttributes = async (params) => {
    const response = await fetch(`${BASE_URL}/attributes?${Utils.ObjectToQueryString(params)}`, {
        method: 'GET',
        headers: Utils.commonHeaders(),
    });
    return Utils.handleNon200s(response);
}

export const postAttribute = async (data) => {
    const response = await fetch(`${BASE_URL}/attributes`, {
        method: 'POST',
        headers: Utils.commonHeaders(),
        body: JSON.stringify(data)
    });
    return Utils.handleNon200s(response);
}

export const putAttribute = async (data) => {
    const response = await fetch(`${BASE_URL}/attributes/${data.id}`, {
        method: 'PUT',
        headers: Utils.commonHeaders(),
        body: JSON.stringify(data)
    });
    return Utils.handleNon200s(response);
}

export const getAttribute = async (params) => {
    const response = await fetch(`${BASE_URL}/attributes/${params.id}`, {
        method: 'GET',
        headers: Utils.commonHeaders(),
    });
    return Utils.handleNon200s(response);
}

export const deleteAttribute = async (params) => {
    const response = await fetch(`${BASE_URL}/attributes/${params.id}`, {
        method: 'DELETE',
        headers: Utils.commonHeaders(),
    });
    return Utils.handleNon200s(response);
}

export const importAttribute = async (data) => {
    try {
        const response = await fetch(`${BASE_URL}/attributes/import`, {
            method: 'POST',
            headers: Utils.commonHeaders(),
            body: JSON.stringify(data)
        });
        return Utils.handleNon200s(response);
    } catch (error) {
        console.log('error: ', error);
        return false;
    }
}